.detail {
  width: 100%;
  float: left;
  img {
    width: 100%;
  }
  @media (max-width: $sm) {
    float: none; 
    padding-top: 75px; 
    display: flex;
    flex-direction: column-reverse;
  }
  padding: $global-padding;
}
.detail-desc {
  position: fixed;
  min-width: 300px;
  left: calc(70% + $global-padding); 
  width: 30%;
  padding-right: 50px;
  @media (max-width: $sm) {
    position: relative;
    width: 100%; 
    left: 0; 
    padding: 0;
  }
}
.detail-gallery {
  position: absolute;
  left: $global-padding;
  width: calc(70% - $global-padding);
  @media (max-width: $sm) {
    position: relative;
    left: 0; 
    width: 100%; 
  }
  video {
    width: 100%; 
  }
}
h1.detail-title {
  margin-bottom: 8px;
  font-size: 2rem;
}
p.gallery-short-description {
  line-height: 135%; 
  font-size: 0.85rem; 
  @media (max-width: $sm) {
    font-size: 1rem; 
  }
}
