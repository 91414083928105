.home {
  background: $color-white;
  width: 100%;

  img,
  figure {
    width: 100%;
    height: auto;
  }
}
.home-hero {
  // margin-bottom: 10vh;
  margin-bottom: $global-padding;
  padding: $global-padding;
  @media (max-width: $sm) {
      margin-top: 50px; 
    }

  p {
    font-size: 1.5rem;
    color: $color-highlight;
  }
}

.gallery-featured-image-wrapper {
  
  height: 75vh; 

  h2 {
    margin-bottom: 8px;
  }
  a {
    display: flex; 
    flex-direction: row;
    height: 100%; 
    gap: $global-padding;
    margin-top: -$global-padding;
    @media (max-width: $sm) {
      gap: 16px;
      flex-direction: column;
      flex-direction: column-reverse;
    }
  }
  .featured-image-desc-wrapper {
    width: calc(30% - $global-padding); 
    @media (max-width: $sm) {
      width: 100%;
    }
  }
  .featured-large-image-mask {
    width: 80%; 
    height: 100%; 
    background-size: cover; 
    background-position: center center;
    @media (max-width: $sm) {
      width: 100%;
    }
  }
  img {
    max-height: 50%;
    max-height: 500px;  
    width: auto;  
  }

  
}