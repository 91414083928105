.home-wrapper {

    a {
    width: 100%;
    text-decoration: none;
    display: block;
    color: $color-black;
    
    img {
      width: 100%;
      margin-top: 16px;
    }
  }
}
.gallery-inner-link {
    transition: all 0.3s $ease-out-expo;
    -webkit-backface-visibility: hidden;
      -moz-backface-visibility:    hidden;
      -ms-backface-visibility:     hidden;
    &:hover {
      transform: scale3d(1.01,1.01,1.01);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility:    hidden;
      -ms-backface-visibility:     hidden;
      transition: all 0.3s $ease-out-expo;
    }
  } 
.gallery {
  a {
    transition: all 0.3s $ease-out-expo;
    -webkit-backface-visibility: hidden;
      -moz-backface-visibility:    hidden;
      -ms-backface-visibility:     hidden;
    &:hover {
      transform: scale3d(1.05, 1.05, 1.05);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility:    hidden;
      -ms-backface-visibility:     hidden;
      transition: all 0.3s $ease-out-expo;
    }
  } 
  display: flex;
  

  h2 {
    display: inline-block;
    width: 100%;
    padding: 8px 0;
  }

  figure {
    margin-bottom: 50px;
  }
  @media (max-width: $sm) {
    display: block;
    width: 100%;
  }
 
}

.gallery-featured-image-wrapper {
  padding: $global-padding;

}
.gallery-short-description {
  display: block;
  font-size: 1.25em;
  padding: 8px 0;
  border-top: 1px solid $color-border;
}
.gallery-large {
  width: 35%;
  display: flex;
  flex-direction: column;
  padding: $global-padding;
  @media (max-width: $sm) {
      width: 100%;
    }
}

.gallery-small-wrapper {
  width: 65%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    width: 33%; 
    @media (max-width: $sm) {
      width: 100%;
    }
  }
  @media (max-width: $sm) {
    display: block;
    width: 100%;
  }
}
.gallery-small {
  padding: $global-padding;
  @media (max-width: $sm) {
    padding-bottom:16px;
  }
  
}
// .gallery-small {
//   width: 33.33%;
// }
