.about-intro {
  margin-top: $global-padding;
      padding: 0 $global-padding;
  @media (max-width: $sm) {
      margin-top: 50px; 
    }
  h1 {
    margin-bottom: 0;
  }
  p {
      line-height: 125%;
      column-count: 2;
      max-width: 640px;  
      width: 100%;
      @media (max-width: $sm) {
        column-count: 1;
      }
      span {
        display: inline-block;
        margin-top: 24px; 
      }
  }
}
.about-intro-paragraph {
  display: grid; 
  grid-auto-flow: column; 
  grid-auto-columns: 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  grid-template-areas: 
    ". ."; 
  justify-content: center; 
  align-content: center; 
  width: 100%; 
  height: 100%; 
   @media (max-width: $sm) {
      display: block;
    }
  .video-wrapper {
    overflow: hidden;
      padding-left: $global-padding;
      align-self: center;
      @media (max-width: $sm) {
        padding: 0;
      }
    video {
      height: 100%;
      width: 177.77777778%; /* 100 * 16 / 9 */
      min-width: 100%;
      min-height: 56.25%; /* 100 * 9 / 16 */
       @media (max-width: $sm) {
        width: 100%; 
        height: auto; 
        min-width: initial;
        min-height: initial;
      }
    }
    }
}
.client-listings {
  padding: $global-padding;
  h2 {
    margin-bottom: $global-padding; 
    border-top: 1px solid $color-border;
    padding: $global-padding 0; 
    border-bottom: 1px solid $color-border;
  }
}
.about-list {
  width: 100%;
  gap: 0px 0px;

  &-inner {
    column-count: 4; 
    @media (max-width: $sm) {
        column-count: 2; 
      }
  }
  div {
    width: 100%;
    font-size: 1.25rem;
    padding: 8px 0;
  }
  padding-bottom: $global-padding;
  margin-bottom: 50px;
  &:last-child {
    border-bottom: none;
  }
}
