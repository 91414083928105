@import 'utils/resets';
@import 'utils/variables';
@import 'utils/fontsizes';
@import 'utils/functions';
@import 'utils/mixins';
@import 'utils/vendors';

@import 'components/navigation';

@import 'pages/gallery/gallery';
@import 'pages/home/home';
@import 'pages/detail/detail';
@import 'pages/about/about';

*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  width: 100%;
  font-size: 16px;
  font-family: $font-primary;
}
.content {
  width: 100%;
}
