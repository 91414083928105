@font-face{
  font-family:"Neue Haas Grotesk Disp W01_55R";
  src:url("./utils/fonts/5549029/55roman.woff2") format("woff2");
}


$sm: 450px;
$md: 800px;
$lg: 1200px;

$color-white: #fff;
$color-black: #000;
$color-highlight: #542ca9;
$color-border: #aaa;
$global-padding: 25px;


$font-primary: 'Neue Haas Grotesk Disp W01_55R', serif;
$font-secondary: 'Roboto', sans-serif;

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);

$z-indexes: ('navigation', 'content');
