/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
*/


h1 {
  font-size: 3rem;
  max-width: 18ch;
  margin-bottom: 2rem;
}
h2 {
    font-size: 2rem;
  }
h6 {
  font-size: 2rem;
  margin-bottom: 8px;
  color: $color-highlight;
}
