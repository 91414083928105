.navigation {
  width: 100%;
  padding: 12px;

  @media (max-width: $sm) {
    padding: 20px 10px;
  }
  .right-nav {
    position: absolute;
    top: 10px;
    right: 10px;
    @media (max-width: $sm) {
      top: 20px;
    }
    a {
      text-decoration: none;
      color: $color-black;
      font-size: 1.25rem;
      padding: 15px;
      @media (max-width: $sm) {
        font-size: 1rem;
      }
      @media (max-width: $md) {
        padding: 15px 8px;
      }
      &:hover {
        color: $color-highlight;
      }
    }
  }
  ul {
    li {
      display: inline-block;
      margin-right: 60px;
      @media (max-width: $sm) {
        margin-right: 0px;
      }
      @media (max-width: $md) {
        margin-right: 0px;
      }
      a {
        padding: 15px;
        text-decoration: none;
        font-size: 1.25rem;
        color: black;
        &:hover {
          color: $color-highlight;
        }
        @media (max-width: $sm) {
          font-size: 1rem;
        }
      }
    }
  }
}

.mobile.nav {
  display: none;
  @media (max-width: $sm) {
    display: flex;
  }
  position: absolute;
  flex-direction: row;
  justify-content: space-evenly;
  top: 50px;
  left: $global-padding;
  width: calc(100% - $global-padding * 2);
  border-top: 1px solid $color-border;
  padding: 16px $global-padding;
  a {
    text-decoration: none;
    color: black;
  }
  .social {
    display: none;
    @media (max-width: $sm) {
      display: inline-block;
    }
  }
}

.social {
  @media (max-width: $sm) {
    display: none;
  }
}

.logo {
  z-index: -1; 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  align-items: center;
  @media (max-width: $sm) {
    width: auto;
    left: 65%;
    top: 5px;
  }
  svg {
    height: 48px;
    width: 48px;
  }
}
